import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ConfidenceQuestion = makeShortcode("ConfidenceQuestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "checking-in"
    }}>{`Checking in`}</h1>
    <ConfidenceQuestion id="confidenceConflict" mdxType="ConfidenceQuestion" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      